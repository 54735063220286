html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;

  font-family: "Helvetica Neue", "Calibri", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;

  background: #000;
  color: #cdcdcd;
}

#root {
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  width: 490px;
  height: 270px;
  background: rgba(32, 32, 32, 0.97);
  border-radius: 3px;

  flex-direction: column;
  align-items: center;

  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}

.title-line {
  margin-top: 45px;
  font-size: 40px;
  color: #a7a29c;
  text-align: center;
}

.sound-icon {
  position: relative;
  width: 65px;
  height: 65px;
  top: -2px;
}

.title-text {
  margin-left: 12px;
}

.sound-icon,
.title-text {
  vertical-align: middle;
}

.button-cnt {
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
}

.auth-btn {
  width: 180px;
  height: 53px;
  border: 1px solid;
  border-radius: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
}

.auth-btn:active {
  color: white;
  border-color: white;
}

.auth-btn + .auth-btn {
  margin-left: 45px;
}

.login-link {
  color: #e6ba54;
  border-color: #d09c23;
  box-shadow: 0 0 20px rgba(233, 173, 35, 0.11) inset;
}

.login-link:hover {
  background-color: rgba(225, 161, 15, 0.13);
}

.create-link {
  color: #b7b7b7;
  border-color: #949494;
  box-shadow: 0 0 20px rgba(207, 207, 207, 0.11) inset;
}

.create-link:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.footer {
  color: #aaa;
  margin-bottom: 36px;
}

.sso-faq-link {
  color: white;
  text-decoration: none;
}

.sso-faq-link:hover {
  text-decoration: underline;
}
